import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';

import { CloudKit } from '../data';

import { gsap } from "gsap";

const wines = [
    "14879415",
    "14555525",
    "14919931",
    "13903479",
    "12717084",
    "14879950",
    "14307484",
    "14827615",
    "13574370",
    "14324073",
    "14805951",
    "12623088",
    "14467939",
    "14088082",
    "14822531",
    "12610789",
    "12592605",
    "14861426",
    "14879589",
    "13111228",
    "14781848",
    "13200191",
    "14822654",
    "14885794",
    "14795988",
    "12951356",
    "14834330",
    "14064857",
    "14809708",
    "13442916",
    "13041830",
    "13200183",
    "13942929",
    "14777902",
    "13232581",
    "11376994",
    "14441472",
    "14834305",
    "13676093",
    "10689729",
    "14713386",
    "14587818",
    "12025301",
    "11195747",
    "12640603",
    "14793494",
    "11517515",
    "14292614",
    "14227257",
    "14789621",
    "14504699",
    "14785873",
    "14777929",
    "14791886",
    "14538320",
    "14092680",
    "14614361",
    "13509244",
    "14789639",
    "14559913",
    "11194701",
    "14562098",
    "14502001",
    "11632441",
    "14752035",
    "14019204",
    "13567524",
    "11180334",
    "14782824",
    "14801280",
    "14427654",
    "14141015",
    "13498881",
    "14205648",
    "14234650",
    "14512752",
    "14560113",
    "14745628",
    "11094621",
    "14712164",
    "14559930",
    "10520819",
    "13697214",
    "14727243",
    "14555550",
    "13997401",
    "11811765",
    "14002234",
    "13593335",
    "14172719",
    "14556237",
    "963355",
    "14460331",
    "11896501",
    "13660315",
    "14752019",
    "14781961",
    "13288218",
    "14727235",
    "11466130",
    "13907832",
    "14538910",
    "14568334",
    "14515363",
    "12888051",
    "14422677",
    "14725029",
    "14704009",
    "13014372",
    "14782154",
    "13505032",
    "14730557",
    "14497759",
    "13211843",
    "14496481",
    "13565481",
    "14438089",
    "13958365",
    "13995027",
    "14731921",
    "14782162",
    "14426379",
    "11666123",
    "13471039",
    "13994542",
    "14427646",
    "14682508",
    "11460660",
    "12889409",
    "10838982",
    "898411",
    "14562101",
    "14491091",
    "12604098",
    "14205699",
    "14792184",
    "14223184",
    "14426117",
    "14426117",
    "14075206",
    "14004125",
    "13350579",
    "11965182",
    "439331",
    "14477977",
    "12604063",
    "14509887",
    "11625727",
    "14160742",
    "14446601",
    "13113581",
    "14703946",
    "13343264",
    "13113952",
    "13591971",
    "11668241",
    "14682495",
    "11903619",
    "14432744",
    "11587134",
    "14057761",
    "13349800",
    "12254420",
    "14699035",
    "13744869",
    "14622880",
    "11518761",
    "12244547",
    "12396794",
    "14713061",
    "13632066",
    "14427654",
    "14015596",
    "14022391",
    "13841431",
    "13989858",
    "12475353",
    "14278812",
    "13189594",
    "11317177",
    "13728851",
    "13794111",
    "13575815",
    "13944191",
    "13574687",
    "14205226",
    "12587136",
    "14211167",
    "642652",
    "13657571",
    "12829051",
    "11097451",
    "13632365",
    "13476201",
    "14154131"
]

const HomePage = () => {

    const [wineInfos, setWineInfos] = useState([]);
    const [idx, setIdx] = useState(0);

    const [stores, setStores] = useState([]);

    const [loadingState, setLoadingState] = useState('start');

    const tl = useRef();

    useEffect(() => {

        if (loadingState !== "start") return;

        var container = CloudKit.getDefaultContainer();
        var publicDB = container.publicCloudDatabase;

        var getPosts = {
            recordType: 'CD_Post',
            sortBy: [{
                fieldName: 'CD_title',
            }]
        };

        setLoadingState("loading");

        publicDB.performQuery(getPosts)
            .then(function (response) {
              if(response.hasErrors) {
      
                // Handle them in your app.
                throw response.errors[0];
      
              } else {
                //console.log(posts);
                let posts = parseCloudKitResponse(response.records).filter(item => item.productImageUrl.includes('http'));

                let styles = computeContainerStyle(posts.length);

                let postsWithStyle = posts.map((item, index) => {

                    return {...item , style: styles[index] };
                })

                return navigator.geolocation.getCurrentPosition(position => {

                    let getStores = {
                        recordType: 'StoreLocation',
                        sortBy: [{
                          fieldName: 'location',
                          relativeLocation: { longitude: position.coords.longitude, latitude: position.coords.latitude }
                        }],
                        filterBy: [{
                            fieldName: 'location',
                            comparator: 'NEAR',
                            distance: 10000,
                            fieldValue: { value: { longitude: position.coords.longitude, latitude: position.coords.latitude }}
                        }]
                      };
        
                    publicDB.performQuery(getStores/* , {  resultsLimit: 10 } */)
                    .then(function (response) {
                      if(response.hasErrors) {
              
                        // Handle them in your app.
                        throw response.errors[0];
              
                      } else {
                        let stores = parseCloudKitResponse(response.records).map(store => {
                            let newStore = store;

                            newStore.distance = getDistanceFromLatLonInKm(position.coords.latitude, position.coords.longitude, store.location.latitude, store.location.longitude)

                            return newStore;

                        });
        
                        //console.log(stores);
        
                        let storeIds = stores.map(item => item.storeId);
        
                        let getProductStocks = {
                            recordType: 'CD_ProductStore',
                            filterBy: [{
                                fieldName: 'CD_store',
                                comparator: 'IN',
                                fieldValue: { value: [...storeIds, '09c433fa-a2ff-11ec-b3f9-a9f4ca191d47'] }
                            },
                            {
                                fieldName: 'CD_quantity',
                                comparator: 'GREATER_THAN',
                                fieldValue: { value: 0 }
                            }]
                        };

                        setStores(stores);
        
                        /* return publicDB.performQuery(getProductStocks) */
                        return queryAll(publicDB, getProductStocks).then(function (response) {
                          if(response.hasErrors) {
                  
                            // Handle them in your app.
                            throw response.errors[0];
                  
                          } else {
                            let productStocks = parseCloudKitResponse(response).map(stock => {
                                let newStock = stock;

                                let store = stores.find(item => item.storeId === stock.store);

                                if (store) {
                                    newStock.distance = store.distance;
                                } else {
                                    newStock.distance = 0;
                                }
                                
                                return newStock;
                            });

                            console.log('productStocks', productStocks)
                
                            let newComputedPosts = posts.map(post => {

                                let newPost = post;

                                newPost.stock = productStocks.filter(stock => stock.publicProduct === post.publicProduct).sort((a,b) => a.distance - b.distance);

                                if (newPost.stock.length > 0) {
                                    newPost.distance = newPost.stock[0].distance;

                                    if  (newPost.distance === 0) {
                                        newPost.onlineStock = true;
                                        if (newPost.stock[1]) {
                                            newPost.distance = newPost.stock[1].distance;
                                        }
                                    }
                                } else {
                                    newPost.distance = 999;
                                }
                                

                                return newPost;
                            }).sort((a,b) => a.distance - b.distance);

                            let postsWithStyle2 = newComputedPosts.map((item, index) => {

                                return {...item , style: styles[index] };
                            })

                            setWineInfos(postsWithStyle2);
                
                            setLoadingState("complete");

                            //console.log(productStocks);

                            return productStocks;  
                          }
                        }); 
                      }
                    });
                }, () => {
                    setWineInfos(postsWithStyle);
                    setLoadingState("complete");
                }, { enableHighAccuracy: true });  
              }
        });


    }, [])

    useLayoutEffect(() => {
    
        if (loadingState !== "complete") return;

        tl.current = gsap.timeline({ delay: 0.5 });
        
        for (let i=0; i < wineInfos.length; i++) {
            if (i < 9) {
                let time = Math.random() / 2;
                tl.current.fromTo(`#wine-${i}-bg`, { scale: 0, opacity: 0 }, { scale: 1, opacity: 1, ease: "elastic.out(0.55, 0.4)", duration: 1 }, time);
                tl.current.fromTo(`#wine-${i}-img`, { scale: 0, opacity: 0 }, { scale: 1, opacity: 1, ease: "elastic.out(0.55, 0.4)", duration: 1 }, time + 0.1);
            } else {
                tl.current.set([`#wine-${i}-bg`, `#wine-${i}-img`], { opacity: 1 }, 0.3);
            }
            
        }

      }, [loadingState]);

    const queryAll = async (database, query, options = {}) => {
        var allRecords = [];
      
        async function doQuery(queryOrPreviousResponse, options) {
             return database.performQuery(queryOrPreviousResponse, options)
                  .then(function(response) {
                       response.records.forEach(function(record){
                            allRecords.push(record);
                       });
                       if(response.moreComing) {
                            return doQuery(response);
                       }
      
                       return allRecords;
                  });
        }
        return doQuery(query, options)
      }

    const parseCloudKitResponse = (array) => {
        let newArray = array.map(item => {
            let newItem = {};

            Object.entries(item.fields).forEach(entry => {
                const [key, { value }] = entry;

                newItem[key.replace('CD_', '')] = value;
            });

            return newItem;
        });

        return newArray;
    }

    const computeContainerStyle = (nbOfItems) => {
        let newStyles = [];

        let startKey = Math.floor(Math.random() * 2) === 1 ? 'top' : 'bottom';

        let colors = ['#8dcac7','#84cef2','#d7686b','#e99c9f','#b58fbc','#f5c792','#cadea2', '#efaf88','#fff6a5'];
        let colorIdx = 0;

        // 
        for (let i=0; i < nbOfItems; i++) {
            let isDifferent = false;
            let newStyle = {
                position: {}
            };

            let key = '';

            if ((i + 4) % 3 === 1) {
                startKey = Math.floor(Math.random() * 2) === 1 ? 'top' : 'bottom';
                key = startKey
            } else if ((i + 4) % 3 === 0) {
                key = startKey;
            } else {
                key = startKey === 'bottom' ? 'top' : 'bottom';
            }

            newStyle.position['top'] = 0;
            
            let height = 0;

            let tries = 0;
            while (!isDifferent && tries < 10) {
                height = 85 - Math.floor(Math.random() * 4) * 5

                if (i > 0) {
                    isDifferent = newStyles[i-1].position.height !== `${height}%`;
                } else {
                    isDifferent = true;
                }

                tries++;
            }

            newStyle.position.height = `${height}%`;

            newStyle.backgroudColor = colors[colorIdx];

            newStyles.push(newStyle);

            colorIdx++

            if (colorIdx === colors.length) {
                colorIdx = 0;
            }
        }

        return newStyles;
    }

    const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2-lat1);  // deg2rad below
        var dLon = deg2rad(lon2-lon1); 
        var a = 
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
            Math.sin(dLon/2) * Math.sin(dLon/2)
            ; 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c; // Distance in km
        return d;
    }
        
    const deg2rad = (deg) => {
        return deg * (Math.PI/180)
    }

    return (<>
        <main className='h-full w-full overflow-auto bg-gray-50'>
            <section className='sticky top-0 z-30 w-full mx-auto py-5 lg:pt-5 lg:mt-5 lg:pb-5 backdrop-blur-sm bg-gray-50/75'>
                <div className='flex flex-col justify-center items-center font-title'>
                    <div className="select-none text-center leading-none font-title text-3xl text-gray-800" style={{lineHeight:'2rem', fontSize:'3rem', marginTop: '1rem' }}>
                        Sommelier<br /><span className="leading-none font-title text-2xl" style={{lineHeight:'2rem', fontSize:'2.5rem'}}>du dimanche</span>
                    </div>
                </div>
            </section>
            <section className='w-full lg:w-10/12 mx-auto px-3 py-3 lg:px-5 lg:py-5 flex flex-row max-w-2xl flex-wrap'>
                {wineInfos.length > 0 &&  wineInfos.map((item, index) => 
                    <WineCard wine={item} index={index} />
                )}
            </section>
        </main>
        {/* <div className='z-50 fixed top-0 left-0 w-screen h-screen flex items-center bg-gray-900/10'>
            <div className='max-w-5xl rounded-2xl m-auto p-2 flex flex-row shadow-2xl bg-gray-50 border border-gray-200/75'>
                <div className='w-full max-w-md p-5 rounded-xl' style={{ backgroundColor: '#8dcac7' }}>
                    <img class="" id="wine--1-img" alt="tyl" src="https://www.saq.com/media/catalog/product/1/3/13632365-1_1580934012.png?quality=10&amp;fit=bounds&amp;height=750&amp;width=500" />
                </div>
            </div>
        </div> */}
    </>);
}

const WineCard = ({ wine, index }) => {

    const ref = useRef();

    useEffect(() => {

        const el = ref;
        if (el && el.current) {
                let state = {
                    mouseX: 0,
                    mouseY: 0,
                    height: el.current.clientHeight,
                    width: el.current.clientWidth
                };

                el.current.removeEventListener("mousemove", ele => {
                    const cardBg = el.current.querySelector(`#wine-${index}-image`);
                    const card = el.current.querySelector(`#wine-${index}`);
                    state.mouseX = ele.pageX - el.current.offsetLeft - state.width / 2;
                    state.mouseY = ele.pageY - el.current.offsetTop - state.height / 2;
        
                    // parallax angle in card
                    const angleX = (state.mouseX / state.width) * 10;
                    const angleY = (state.mouseY / state.height) * -10;
                    el.current.style.transform = `rotateY(${angleX}deg) rotateX(${angleY}deg)`;
        
                    // parallax position of background in card
                    const posX = (state.mouseX / state.width) * -20;
                    const posY = (state.mouseY / state.height) * -20;
                    cardBg.style.transform = `translateX(${posX}px) translateY(${posY}px)`;

                    el.current.style.transition = '0.6s cubic-bezier(0.23, 1, 0.32, 1)';
                    cardBg.style.transition = '0.6s cubic-bezier(0.23, 1, 0.32, 1)';
                });
        
                el.current.removeEventListener("mouseout", () => {
                    const cardBg = el.current.querySelector(`#wine-${index}-image`);
                    const card = el.current.querySelector(`#wine-${index}`);
                    card.style.transform = `rotateY(0deg) rotateX(0deg) `;
                    cardBg.style.transform = `translateX(0px) translateY(0px)`;

                    /* card.style.transition = '1s cubic-bezier(0.445, 0.05, 0.55, 0.95)';
                    cardBg.style.transition = '1s cubic-bezier(0.445, 0.05, 0.55, 0.95)'; */
                });
        
                el.current.addEventListener("mousemove", ele => {
                    const cardBg = el.current.querySelector(`#wine-${index}-image`);
                    const card = el.current.querySelector(`#wine-${index}`);
                    state.mouseX = ele.pageX - el.current.offsetLeft - state.width / 2;
                    state.mouseY = ele.pageY - el.current.offsetTop - state.height / 2;
        
                    // parallax angle in card
                    const angleX = (state.mouseX / state.width) * -15;
                    const angleY = (state.mouseY / state.height) * 15;
                    
        
                    // parallax position of background in card
                    const posX = (state.mouseX / state.width) * 10;
                    const posY = (state.mouseY / state.height) * 10;
                    cardBg.style.transform = `translateX(${posX}px) translateY(${posY}px)`;
                    el.current.style.transform = `translateX(${posX / 2}px) translateY(${posY /2}px) rotateY(${angleX}deg) rotateX(${angleY}deg)`;

                    el.current.style.transition = '0.6s cubic-bezier(0.23, 1, 0.32, 1)';
                    cardBg.style.transition = '0.6s cubic-bezier(0.23, 1, 0.32, 1)';
                });
        
                el.current.addEventListener("mouseout", () => {
                    const cardBg = el.current.querySelector(`#wine-${index}-image`);
                    const card = el.current.querySelector(`#wine-${index}`);
                    el.current.style.transform = `rotateY(0deg) rotateX(0deg) `;
                    cardBg.style.transform = `translateX(0px) translateY(0px)`;

                    /* card.style.transition = '1s cubic-bezier(0.445, 0.05, 0.55, 0.95)';
                    cardBg.style.transition = '1s cubic-bezier(0.445, 0.05, 0.55, 0.95)'; */
                });

            return function cleanup() {
                el.current.removeEventListener("mousemove", ele => {
                    const card = el.current.querySelector(`#wine-${index}-image`);
                    const cardBg = el.current.querySelector(`#wine-${index}`);
                    state.mouseX = ele.pageX - el.current.offsetLeft - state.width / 2;
                    state.mouseY = ele.pageY - el.current.offsetTop - state.height / 2;
        
                    // parallax angle in card
                    const angleX = (state.mouseX / state.width) * 10;
                    const angleY = (state.mouseY / state.height) * -10;
                    /* card.style.transform = `rotateY(${angleX}deg) rotateX(${angleY}deg)`;
                    cardBg.style.transform = `rotateY(${angleX * 2}deg) rotateX(${angleY * 2}deg)`; */
        
                    // parallax position of background in card
                    const posX = (state.mouseX / state.width) * -20;
                    const posY = (state.mouseY / state.height) * -20;
                    card.style.transform = `translateX(${posX / 2}px) translateY(${posY / 2}px) rotateY(${angleX}deg) rotateX(${angleY}deg)`;
                    cardBg.style.transform = `translateX(${posX}px) translateY(${posY}px) rotateY(${angleX * 2}deg) rotateX(${angleY * 2}deg)`;

                    card.style.transition = '0.6s cubic-bezier(0.23, 1, 0.32, 1)';
                    cardBg.style.transition = '0.6s cubic-bezier(0.23, 1, 0.32, 1)';
                });
        
                el.current.removeEventListener("mouseout", () => {
                    const card = el.current.querySelector(`#wine-${index}-image`);
                    const cardBg = el.current.querySelector(`#wine-${index}`);
                    card.style.transform = `rotateY(0deg) rotateX(0deg) `;
                    cardBg.style.transform = `translateX(0px) translateY(0px)`;

                    /* card.style.transition = '1s cubic-bezier(0.445, 0.05, 0.55, 0.95)';
                    cardBg.style.transition = '1s cubic-bezier(0.445, 0.05, 0.55, 0.95)'; */
                });
            };
        }
    }, []);

    return (
        <div key={wine.id} ref={ref} className='w-1/3 px-2 py-3 lg:px-6 lg:py-7 flex flex-col relative group' style={{transformStyle: 'preserve-3d', transform: 'perspective(400px)', transition: '0.6s cubic-bezier(0.23, 1, 0.32, 1)'}}>
            <div id={`wine-${index}`} className='p-2 lg:px-3 lg:py-3 absolute left-0 w-full h-full' style={wine.style.position}>
                <div id={`wine-${index}-bg`} className='py-3 px-3 lg:px-5 lg:py-5 rounded-2xl md:rounded-2xl h-full relative ' style={{ backgroundColor: wine.style.backgroudColor, opacity: 0 }}>
                    {wine.stock && wine.stock.length > 0 && <div className='absolute top-0 left-0 my-2 mx-2 md:my-2 md:mx-2 px-0.5 md:px-1'> {/* py-.5 px-1 lg:py-1 lg:px-2 */}
                        <div className='text-xs md:text-sm brightness-75 bg-clip-text bg-gray-200 text-transparent font-bold' style={{ backgroundColor: wine.style.backgroudColor }}>
                        {/* <div className='text-xs md:text-sm text-gray-50 font-bold'> */}
                            {wine.onlineStock && wine.stock[1] ? 
                                <>
                                {'En ligne'}<br />{`${Math.round(wine.stock[1].distance)} `}<span className='font-normal'>km</span>
                                </>
                            :
                            wine.distance === 0 ?
                                <>{'En ligne'}</>
                            :
                                <>{`${Math.round(wine.distance)} `}<span className='font-normal'>km</span></>
                            }
                        </div>
                    </div>}
                    <div className='absolute top-0 right-0 my-2 mx-2 md:my-2 md:mx-2 px-0.5 md:px-1'>
                        <div className='text-xs md:text-sm brightness-75 bg-clip-text text-transparent font-bold' style={{ backgroundColor: wine.style.backgroudColor}}>
                            {Math.round(wine.productPrice)}<span className='font-normal'>$</span>
                        </div>
                    </div>
                </div>
            </div>
            <div id={`wine-${index}-image`} className='py-5 lg:py-4 z-10 rounded-2xl' style={{transition: '0.6s cubic-bezier(0.23, 1, 0.32, 1)'}}>
                <img className='' id={`wine-${index}-img`} alt='tyl' src={wine.productImageUrl + '?quality=10&fit=bounds&height=375&width=250'} style={{ opacity: 0/*, transformOrigin: !item.style.style.bottom ? 'top' : 'bottom'*/ }} />
            </div>

            <a href={`https://www.saq.com/fr/${wine.codeSAQ}`} className='absolute top-0 left-0 w-full h-full z-20 cursor-pointer'></a>
        </div>
    )
}

export default HomePage;