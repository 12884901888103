window.CloudKit.configure({
  containers: [{

  // Change this to a container identifier you own.
  containerIdentifier: 'iCloud.ducharme.SommelierDuDimanche',

  apiTokenAuth: {
      // And generate a web token through CloudKit Dashboard.
      apiToken: process.env.REACT_APP_CLOUD_KIT_API_TOKEN,

      persist: true, // Sets a cookie.
      
      /* signInButton: {
      id: 'apple-sign-in-button',
      theme: 'black' // Other options: 'white', 'white-with-outline'.
      },

      signOutButton: {
      id: 'apple-sign-out-button',
      theme: 'black'
      } */
  },

  environment: 'development'
  }]
});

const CloudKit = window.CloudKit;

export {
    CloudKit,
}